import { useNavigate } from 'react-router-dom';
import { AppRoutes, GTMEvent } from 'src/types';
import styles from './ChangePlanButton.module.scss';
import { sendGTMEvent } from 'src/utils';
import { useSession, useSubscriptionInfo } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

export const ChangePlanButton = () => {
  const navigate = useNavigate();
  const { isEnterpriseTier } = useSession();
  const { onToggleVisibleContactNinjaModal } = useContext(SessionContext);

  const { subscriptionInfo, isSubscriptionInfoLoading } = useSubscriptionInfo();

  const handleChangeButtonClick = () => {
    if (subscriptionInfo?.source === 'stripe' && !isEnterpriseTier) {
      navigate(AppRoutes.PAYMENT);
    } else {
      onToggleVisibleContactNinjaModal(true);
    }
    sendGTMEvent(GTMEvent.CHANGE_SUBSCRIPTION_CLICK);
  };

  return (
    <button
      type="button"
      className={styles.button}
      onClick={handleChangeButtonClick}
      disabled={
        isSubscriptionInfoLoading || subscriptionInfo?.has_scheduled_downgrade
      }
    >
      Change plan
    </button>
  );
};
