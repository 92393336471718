import { useMemo } from 'react';
import { useGetUserSubscriptionInfoQuery } from 'src/store/services';
import { useSession } from 'src/hooks';

export const useSubscriptionInfo = () => {
  const { appUser, isSubscribedTier } = useSession();

  const { data: subscriptionInfo, isLoading: isSubscriptionInfoLoading } =
    useGetUserSubscriptionInfoQuery(
      {
        user_id: appUser.user_id,
      },
      { skip: !isSubscribedTier },
    );

  return useMemo(
    () => ({
      subscriptionInfo,
      isSubscriptionInfoLoading,
    }),
    [subscriptionInfo, isSubscriptionInfoLoading],
  );
};
