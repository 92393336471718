import { env } from 'src/env';
import packageJson from '../../package.json';
import { AppUser, ConversationRole, UserType } from 'src/types';

export const E_ASSISTANT = env.REACT_APP_E_ASSISTANT || 'EASSISTANT';

export const DEFAULT_PORTAL = 'manage-tasks';

export const DEFAULT_OPTION = 'default';

export const VERSION_NUMBER = packageJson.version;

// todo: play with these settings if they work on all env after push
export const APP_ENV =
  env.REACT_APP_ENVIRONMENT || env.NODE_ENV || 'development';

export const DEFAULT_AGENT: AppUser = {
  user_id: E_ASSISTANT,
  first_name: 'Ninja',
  last_name: '',
  user_type: UserType.AGENT,
  role: ConversationRole.AGENT,
  email: 'ninja@ninjatech.ai',
  company: 'NinjaTech AI',
  avatar: 'avatarAtlas',
};

export const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const SCHEDULE_ACTION_CREATED = 'created';
export const SCHEDULE_ACTION_DELETED = 'deleted';

export const REGEX_EMAIL_PATTERN =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const CALENDAR_URL = 'https://www.googleapis.com/auth/calendar';

// This permissions is only needed for workspace user as opposed to just normal google users,
// i.e with a @gmail.com domain
export const DIRECTORY_URL =
  'https://www.googleapis.com/auth/directory.readonly';

export const CONTACTS_URL = 'https://www.googleapis.com/auth/contacts.readonly';

export const PRODUCTION_ENVIRONMENT = 'production';

export const IMAGE_FILE_EXTENSION_LIST = [
  'png',
  'jpg',
  'jpeg',
  'apng',
  'bmp',
  'dib',
  'drle',
  'dpx',
  'fits',
  'fit',
  'fts',
  'g3',
  'gif',
  'ico',
  'jp2',
  'jpg2',
  'jfif',
  'pjpeg',
  'pjp',
  'jpm',
  'jpgm',
  'svg',
  'svgz',
  'svg.gz',
  'tif',
  'tiff',
  'tfx',
  'psd',
  'wbmp',
  'xif',
  'pcx',
  'webp',
  'wmf',
  'arw',
  'cr2',
  'crw',
  'dcr',
  'dng',
  'erf',
  'k25',
  'kdc',
  'mrw',
  'nef',
  'nrw',
  'orf',
  'pef',
  'raf',
  'raw',
  'sr2',
  'srf',
  'x3f',
];
