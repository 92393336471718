import { getRoundedPriceWithCurrency, uppercaseFirstLetter } from 'src/utils';
import styles from './SummaryContent.module.scss';
import { SummaryRow } from '../SummaryRow';
import { PaymentPlanInterval, SubscriptionPlan } from 'src/types';

interface SummaryContentProps {
  selectedPlan?: SubscriptionPlan;
  subtotal: number;
  taxes: number;
  discount?: number;
  totalOriginalAmount: number;
  dueToday: number;
}

export const SummaryContent = ({
  selectedPlan,
  subtotal,
  taxes,
  discount,
  totalOriginalAmount,
  dueToday,
}: SummaryContentProps) => {
  return (
    <div className={styles.content}>
      <div className={styles.checkoutResultSection}>
        <div className={styles.checkoutResultSectionRow}>
          <span className={styles.subscriptionRow}>
            {uppercaseFirstLetter(selectedPlan?.plan_tier || '')} subscription
          </span>
          <span>
            {getRoundedPriceWithCurrency(totalOriginalAmount)}/
            {selectedPlan?.period === PaymentPlanInterval.MONTHLY ? 'mo' : 'yr'}
          </span>
        </div>
      </div>

      <hr className="divider no-margin" />

      <div className={styles.checkoutRowWrapper}>
        <SummaryRow
          label="Subtotal"
          value={getRoundedPriceWithCurrency(subtotal)}
        />
        {discount && (
          <SummaryRow
            label="Discount"
            value={`-${getRoundedPriceWithCurrency(discount)}`}
          />
        )}
        <SummaryRow
          label="Tax & fees"
          value={getRoundedPriceWithCurrency(taxes)}
        />

        <div className={styles.checkoutResultSectionRow}>
          <span>Due today (USD)</span>

          <span>{getRoundedPriceWithCurrency(dueToday)}</span>
        </div>
      </div>
    </div>
  );
};
