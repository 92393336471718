import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { useSession, useSubscriptionInfo } from 'src/hooks';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';

type UpsellMessageProps = {
  title?: string;
  dataGTMOpenTier: string;
  dataGTMNinjaTier: string;
  description: string;
  className?: string;
  buttonStyle?: 'simple' | 'with-background';
  buttonLabel?: string;
};
export const UpsellMessage = ({
  title,
  dataGTMOpenTier,
  dataGTMNinjaTier,
  description,
  className,
  buttonStyle = 'simple',
  buttonLabel,
}: UpsellMessageProps) => {
  const { onToggleVisibleContactNinjaModal } = useContext(SessionContext);

  const { isOpenTier, isFreeTier, isProTrialTier, isProTier } = useSession();
  const navigate = useNavigate();

  const { subscriptionInfo } = useSubscriptionInfo();

  const handleProUpgradeClick = () => {
    onToggleVisibleContactNinjaModal();
  };

  const handleUpgradeClick = () => {
    if (isProTier) {
      subscriptionInfo?.source === 'stripe'
        ? navigate(AppRoutes.PAYMENT)
        : handleProUpgradeClick();
    } else {
      navigate(AppRoutes.PAYMENT);
    }
  };

  return (
    <div
      className={classNames(
        'nj-upsell-message-wrapper',
        { isOpenTier },
        className,
      )}
    >
      <div className="nj-upsell-message-text-block-wrapper">
        {title && <h4 className="nj-upsell-message-title">{title}</h4>}
        <p
          className={
            !!title
              ? 'nj-upsell-message-description'
              : 'nj-upsell-message-main-text'
          }
        >
          {description}
        </p>
      </div>
      <div className="nj-upsell-message-button-wrapper">
        {isOpenTier && (
          <Link
            id={dataGTMOpenTier}
            to={AppRoutes.SIGN_UP}
            className={classNames('nj-button-try-pro', {
              'with-background': buttonStyle === 'with-background',
            })}
          >
            {buttonLabel ? buttonLabel : 'Upgrade'}
          </Link>
        )}
        {(isProTrialTier || isFreeTier || isProTier) && (
          <button
            type="button"
            onClick={handleUpgradeClick}
            className={classNames('nj-button-try-pro', {
              'with-background': buttonStyle === 'with-background',
            })}
            id={dataGTMNinjaTier}
          >
            {buttonLabel ? buttonLabel : 'Upgrade'}
          </button>
        )}
      </div>
    </div>
  );
};
