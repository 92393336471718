import { useMemo } from 'react';
import { WelcomeUpsellModal } from 'src/components/WelcomeUpsellModal';
import { useSession, useRecurringUpsellModal } from 'src/hooks';
import {
  useAppDispatch,
  toggleWelcomeModal,
  useAppSelector,
  modalsControlState,
  setWelcomeUpsellModalType,
} from 'src/store';
import { WelcomeUpsellModalType } from 'src/types';

export const WelcomeModal = () => {
  const dispatch = useAppDispatch();

  const { isVisibleWelcomeModal, welcomeUpsellModalType } =
    useAppSelector(modalsControlState);

  const { isEnterpriseTier, isProTier, isUltraTier, isProTrialTier } =
    useSession();
  const { setNewUpsellTimestamp } = useRecurringUpsellModal();

  const handleCloseModal = () => {
    dispatch(toggleWelcomeModal(false));
    dispatch(setWelcomeUpsellModalType(null));

    setNewUpsellTimestamp();
  };

  const modalType: WelcomeUpsellModalType | null = useMemo(() => {
    if (welcomeUpsellModalType) {
      return welcomeUpsellModalType;
    }
    if (isEnterpriseTier) {
      return 'enterprise-tier-welcome-modal';
    }
    if (isUltraTier) {
      return 'ultra-welcome-modal';
    }
    if (isProTier) {
      return 'pro-welcome-modal';
    }
    if (isProTrialTier) {
      return 'pro-trial-welcome-modal';
    }
    return null;
  }, [
    isEnterpriseTier,
    isUltraTier,
    isProTier,
    isProTrialTier,
    welcomeUpsellModalType,
  ]);

  if (!modalType) {
    return null;
  }

  return (
    <WelcomeUpsellModal
      modalType={modalType}
      isOpen={isVisibleWelcomeModal}
      onClose={handleCloseModal}
    />
  );
};
