import { useSession, useBreakpoint } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { PAYPAL_PAYMENT_IN_PROGRESS, SVG_SIZE_L } from 'src/constants';
import dayjs from 'dayjs';
import { Info, Confetti } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'src/types';
import { getWithExpiry } from 'src/utils/localStorage';

export const LowDaysBanner = () => {
  const { isProTrialTier, isEnterpriseTier, appUser } = useSession();
  const { isMobile } = useBreakpoint();
  const navigate = useNavigate();

  const paypalPaymentInProgress =
    getWithExpiry(PAYPAL_PAYMENT_IN_PROGRESS) === 'true';

  const { onToggleVisibleContactNinjaModal } = useContext(SessionContext);

  const handleUpgradeClick = () => {
    navigate(AppRoutes.PAYMENT);
  };

  const handleContactNinjaClick = () => {
    onToggleVisibleContactNinjaModal(true);
  };

  const diff = Math.ceil(
    dayjs(appUser?.tier_expiration_date).diff(dayjs(), 'day', true),
  );

  if (!isProTrialTier && !isEnterpriseTier) {
    return null;
  }

  if (paypalPaymentInProgress) return null;

  return (
    <div className="nj-common-banner">
      <div className="nj-common-banner--left-side-wrapper">
        {isEnterpriseTier && !isMobile && (
          <Info size={SVG_SIZE_L} weight="fill" />
        )}
        {isProTrialTier && !isMobile && (
          <Confetti size={SVG_SIZE_L} weight="fill" />
        )}
        <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
          <h5 className="nj-common-banner--title">
            {isEnterpriseTier
              ? `${diff} day${diff === 1 ? '' : 's'} of Enterprise Trial left`
              : 'Unlimited access to Ninja'}
          </h5>
        </div>
      </div>
      <div className="nj-common-banner--right-side-wrapper">
        {isEnterpriseTier ? (
          <button
            type="button"
            className="nj-button nj-banner--renew-button"
            onClick={handleContactNinjaClick}
          >
            Contact Ninja
          </button>
        ) : (
          <button
            type="button"
            className="nj-button nj-banner--renew-button"
            onClick={handleUpgradeClick}
          >
            Upgrade
          </button>
        )}
      </div>
    </div>
  );
};
