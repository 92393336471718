import classNames from 'classnames';
import {
  useBreakpoint,
  useTaskById,
  useQueryParams,
  useLeftPanelState,
} from 'src/hooks';
import { PencilSimpleLine } from '@phosphor-icons/react';
import { useThemeActions } from 'src/hooks';
import { GTMEvent } from 'src/types';
import { SVG_SIZE_L, SVG_SIZE_M } from 'src/constants';
import { sendGTMEvent } from 'src/utils';
import './AddConversationButton.scss';
import { useContext } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';

interface AddConversationButtonProps {
  className?: string;
}

export const AddConversationButton = ({
  className,
}: AddConversationButtonProps) => {
  const { searchParams, removeSearchParam } = useQueryParams();
  const { setThreadInputBoxValue, setTemporaryInputValue, deleteAttachment } =
    useContext(ThreadInputBoxContext);

  const { toggleTasksListExpanded } = useThemeActions();
  const { isTasksListExpanded } = useLeftPanelState();

  const { removeCurrentTaskId } = useTaskById();
  const { isMobileOrTablet } = useBreakpoint();

  const handleCreateNewChat = async () => {
    if (!!searchParams.conv) {
      removeCurrentTaskId();
      removeSearchParam('conv');
      sendGTMEvent(GTMEvent.NEW_CHAT_INITIATION);
    }

    if (isMobileOrTablet && isTasksListExpanded) {
      toggleTasksListExpanded();
    }

    setTemporaryInputValue('');
    setThreadInputBoxValue('');
    deleteAttachment();

    // do pillars refresh from this button click
    const event = new CustomEvent('refresh-pillars', {
      detail: { message: 'Refresh random pillars' },
    });
    dispatchEvent(event);
  };

  return (
    <div className={classNames('nj-create-new-chat', className)}>
      <div className="nj-create-new-chat--container" role="button" tabIndex={0}>
        <div className="nj-create-new-chat--gradient-shadow" />

        <div className="nj-create-new-chat--gradient-border" />

        <button
          data-e2e="new-chat-button"
          onClick={handleCreateNewChat}
          className="nj-create-new-chat--button"
          tabIndex={-1}
        >
          <div className="nj-create-new-chat--button-container">
            <PencilSimpleLine
              size={isMobileOrTablet ? SVG_SIZE_M : SVG_SIZE_L}
            />
            <span className="nj-create-new-chat--button-title">New chat</span>
          </div>
        </button>
      </div>
    </div>
  );
};
