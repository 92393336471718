import {
  useLazyGetBillingPortalUrlQuery,
  useUpgradeSubscriptionMutation,
} from 'src/store/services';
import { useSession, useSubscriptionInfo } from 'src/hooks';
import { AppRoutes, BillingSettingsNestedRoutes } from 'src/types';
import './CancelSubscription.scss';
import log from 'src/utils/logger';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';

export const CancelSubscription = () => {
  const { appUser } = useSession();
  const [trigger, { isLoading }] = useLazyGetBillingPortalUrlQuery();

  const { subscriptionInfo, isSubscriptionInfoLoading } = useSubscriptionInfo();

  const [upgradeSubscription, { isLoading: isUpgradeSubscriptionLoading }] =
    useUpgradeSubscriptionMutation();

  const { onToggleVisibleCancelPaypalSubscriptionModal } =
    useContext(SessionContext);

  const isCancelDisabled =
    isLoading ||
    isSubscriptionInfoLoading ||
    subscriptionInfo?.is_cancelled ||
    !!appUser?.stripe_info?.renew_early_pending_subscription;

  const isRenewDisabled =
    isSubscriptionInfoLoading ||
    isUpgradeSubscriptionLoading ||
    !!appUser?.stripe_info?.renew_early_pending_subscription;

  // TODO(olha): add error handling
  // TODO(olha): it's a temporary solution.
  const handleClick = async () => {
    if (!!subscriptionInfo && subscriptionInfo?.source === 'stripe') {
      const { data } = await trigger({
        user_id: appUser.user_id,
        return_url: `${AppRoutes.MANAGE_ACCOUNT}/${BillingSettingsNestedRoutes.SUBSCRIPTION}`,
      });
      const redirectUrl = data?.url;

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    } else {
      onToggleVisibleCancelPaypalSubscriptionModal(true);
    }
  };

  const handleRenewClick = async () => {
    if (!subscriptionInfo?.price_info?.lookup_key) {
      return;
    }

    try {
      const result = await upgradeSubscription({
        lookup_key: subscriptionInfo?.price_info?.lookup_key,
        user_id: appUser.user_id,
      }).unwrap();

      const redirectUrl =
        result.checkout_session?.url || result.billing_portal_session?.url;

      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      log.error(error);
    }
  };

  return (
    <div className="nj-cancel-subscription">
      <p className="nj-cancel-subscription--header">
        <span className="nj-cancel-subscription--title">
          Cancel subscription
        </span>

        <span className="nj-cancel-subscription--description">
          On cancellation, your subscription will continue until the end of the
          month. Once subscription is expired your account will be downgraded to
          Basic.
        </span>
      </p>

      <div className="nj-cancel-subscription--footer">
        <button
          disabled={isCancelDisabled}
          onClick={handleClick}
          className="nj-cancel-subscription--submit"
        >
          Cancel subscription
        </button>

        {subscriptionInfo?.is_cancelled && (
          <button
            disabled={isRenewDisabled}
            onClick={handleRenewClick}
            className="nj-renew-subscription--submit"
          >
            Renew subscription
          </button>
        )}
      </div>
    </div>
  );
};
