import styles from './UpgradeDowngradeSummarySection.module.scss';
import { SubscriptionPlan } from 'src/types';
import { CollapsedItem } from 'src/components/CollapsedItem';
import { SummaryHeader } from '../SummaryHeader';
import { SummaryContent } from '../SummaryContent';

type UpgradeDowngradeSummarySectionProps = {
  selectedPlan?: SubscriptionPlan;
};

export const UpgradeDowngradeSummarySection = ({
  selectedPlan,
}: UpgradeDowngradeSummarySectionProps) => {
  const price =
    selectedPlan?.discounted_price !== 0
      ? selectedPlan?.discounted_price
      : selectedPlan?.original_price;

  const header = (
    <SummaryHeader
      selectedPlan={selectedPlan}
      originalPrice={price}
      dueToday={0}
      additionalDescription=", starting next billing cycle"
    />
  );

  const content = (
    <SummaryContent
      selectedPlan={selectedPlan}
      totalOriginalAmount={price || 0}
      subtotal={0}
      taxes={0}
      dueToday={0}
    />
  );

  return (
    <div className={styles.root}>
      <CollapsedItem
        title="$0.00"
        header={header}
        content={content}
        isExpanded={false}
        headerClassName={styles.collapsedItem}
      />
    </div>
  );
};
