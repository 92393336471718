import { File, Warning, XCircle, Image, X } from '@phosphor-icons/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { Spinner } from 'src/components/Loading';
import { SVG_SIZE_M } from 'src/constants';
import { ModalGalleryType } from 'src/types';
import SessionContext from 'src/contexts/SessionContext';
import { useDeleteAttachment } from '../../hooks/useDeleteAttachment';

export const AttachedFileItem = () => {
  const { threadInputBoxFile, attachmentLoading, threadInputBoxFileError } =
    useContext(ThreadInputBoxContext);
  const { onChangeModalGalleryData } = useContext(SessionContext);
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);

  const { onDeleteAttachment } = useDeleteAttachment();

  const isImageFile = useMemo(() => {
    if (!!threadInputBoxFile) {
      const fileTypeParts = threadInputBoxFile.type.split('/');
      if (fileTypeParts.length < 2) {
        return false;
      }
      const firstPart = fileTypeParts[0];
      return firstPart.toLowerCase() === 'image';
    }
    return false;
  }, [threadInputBoxFile]);

  useEffect(() => {
    if (isImageFile && threadInputBoxFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader?.result);
      };
      reader.readAsDataURL(threadInputBoxFile);
    }
  }, [threadInputBoxFile, isImageFile, imageUrl]);

  const renderExceptionIcon = (type: string) => {
    switch (type) {
      case 'image/apng':
      case 'image/bmp':
      case 'image/dib':
      case 'image/drle':
      case 'image/dpx':
      case 'image/fits':
      case 'image/g3':
      case 'image/gif':
      case 'image/ico':
      case 'image/jp2':
      case 'image/jpg2':
      case 'image/jfif':
      case 'image/pjpeg':
      case 'image/pjp':
      case 'image/webp':
      case 'image/jpm':
      case 'image/jpgm':
      case 'image/svg':
      case 'image/tif':
      case 'image/tfx':
      case 'image/psd':
      case 'image/wbmp':
      case 'image/xif':
      case 'image/pcx':
      case 'image/wmf':
      case 'image/arw':
      case 'image/cr2':
      case 'image/crw':
      case 'image/dcr':
      case 'image/dng':
      case 'image/erf':
      case 'image/k25':
      case 'image/kdc':
      case 'image/mrw':
      case 'image/nef':
      case 'image/nrw':
      case 'image/orf':
      case 'image/pef':
      case 'image/raf':
      case 'image/raw':
      case 'image/sr2':
      case 'image/srf':
      case 'image/x3f':
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
        return <Image size={SVG_SIZE_M} />;
      default:
        return <File size={SVG_SIZE_M} />;
    }
  };

  const handleDeleteAttachment = async () => {
    onDeleteAttachment();
  };

  const handleImageClick = (url: string, fileName: string) => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.IMAGES_VIDEOS,
      [
        {
          url: url,
          title: fileName,
          height: 400,
          width: 400,
          source: '',
        },
      ],
      0,
    );
  };

  if (!threadInputBoxFile) {
    return null;
  }

  if (isImageFile && imageUrl) {
    return (
      <div className="nj-thread-input-box--file-image-wrapper">
        {attachmentLoading ? (
          <Spinner size={SVG_SIZE_M} inline />
        ) : !!threadInputBoxFileError ? (
          <Warning size={SVG_SIZE_M} weight="fill" className="error-icon" />
        ) : (
          <img
            src={imageUrl as string}
            alt={threadInputBoxFile?.name}
            className="nj-thread-input-box--file-image"
            onClick={() =>
              handleImageClick(imageUrl as string, threadInputBoxFile?.name)
            }
          />
        )}
        <button
          type="button"
          onClick={handleDeleteAttachment}
          className="nj-thread-input-box--file-label-close"
        >
          <X size={12} weight="bold" />
        </button>
      </div>
    );
  }

  return (
    <div className="nj-thread-input-box--file-label">
      <div className="nj-thread-input-box--file-label-text">
        {attachmentLoading ? (
          <Spinner size={SVG_SIZE_M} inline />
        ) : !!threadInputBoxFileError ? (
          <Warning size={SVG_SIZE_M} weight="fill" className="error-icon" />
        ) : (
          renderExceptionIcon(threadInputBoxFile.type)
        )}
        <span className="nj-thread-input-box--file-name ellipsis">
          {threadInputBoxFile?.name}
        </span>
      </div>
      <button
        type="button"
        onClick={handleDeleteAttachment}
        className="nj-thread-input-box--file-label-close"
      >
        <XCircle size={SVG_SIZE_M} weight="fill" />
      </button>
    </div>
  );
};
