import styles from './OnboardingModal.module.scss';
import { Modal } from 'src/components/Modal';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import {
  LOCAL_STORAGE_VALUE,
  SHOWN_ONBOARDING_MODAL,
  SVG_SIZE_L,
  SVG_SIZE_M,
} from 'src/constants';
import { ArrowSquareOut, XCircle } from '@phosphor-icons/react';
import {
  DEFAULT_NINJATECH_LINK,
  ONBOARDING_IMAGE_DESKTOP,
  ONBOARDING_IMAGE_MOBILE,
} from 'src/constants/externalLinks';
import { useBreakpoint, useRecurringUpsellModal } from 'src/hooks';

export const OnboardingModal = () => {
  const { isOpenOnboardingModal, onChangeIsOpenOnboardingModal } =
    useContext(SessionContext);

  const { isMobile } = useBreakpoint();
  const { setNewUpsellTimestamp } = useRecurringUpsellModal();

  const handleCloseOnboardingModal = () => {
    localStorage.setItem(SHOWN_ONBOARDING_MODAL, LOCAL_STORAGE_VALUE);
    onChangeIsOpenOnboardingModal(false);
    setNewUpsellTimestamp();
  };

  return (
    <Modal
      open={isOpenOnboardingModal}
      onClose={handleCloseOnboardingModal}
      className={styles.onboardingModal}
      overlayClassName={styles.overlay}
      showCloseIcon={false}
      blockScroll={false}
    >
      <div className={styles.root}>
        <button
          type="button"
          className={styles.buttonClose}
          onClick={handleCloseOnboardingModal}
        >
          <XCircle size={SVG_SIZE_L} weight="fill" />
        </button>
        <img
          src={isMobile ? ONBOARDING_IMAGE_MOBILE : ONBOARDING_IMAGE_DESKTOP}
          alt="Ninja"
          className={styles.image}
        />
        <div className={styles.main}>
          <h2 className={styles.title}>
            Meet Ninja <br />
            Your SuperGPT AI Assistant
          </h2>
          <div className={styles.contentWrapper}>
            <div className={styles.textBlock}>
              <h4 className={styles.blockTitle}>
                Get tasks done with AI agents
              </h4>
              <p className={styles.description}>
                Multi-task with <b>unlimited</b> image generation, research,
                writing, scheduling, coding and more.
              </p>
            </div>
            <div className={styles.textBlock}>
              <h4 className={styles.blockTitle}>
                Say goodbye to multiple subscriptions
              </h4>
              <p className={styles.description}>
                Compare answers side-by-side with access to OpenAI GPT, Google
                Gemini, DALL-E 3 and more for only <strong>$15/mo</strong>{' '}
                ($75+/mo value).
              </p>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <a
            href={DEFAULT_NINJATECH_LINK}
            target="_blank"
            rel="noreferrer"
            className={styles.learnMoreLink}
          >
            <>
              <ArrowSquareOut size={SVG_SIZE_M} />
              <span>Learn more</span>
            </>
          </a>
          <button
            type="button"
            className={styles.button}
            onClick={handleCloseOnboardingModal}
          >
            Let’s Go!
          </button>
        </div>
      </div>
    </Modal>
  );
};
