import { useUpdateDowngradeSubscriptionMutation } from 'src/store/services';
import log from 'src/utils/logger';
import { useSession } from 'src/hooks';
import { AppRoutes, SubscriptionPlan } from 'src/types';
import styles from './UpgradeDowngradeButton.module.scss';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type UpgradeDowngradeButtonProps = {
  selectedPlan?: SubscriptionPlan;
};

export const UpgradeDowngradeButton = ({
  selectedPlan,
}: UpgradeDowngradeButtonProps) => {
  const { appUser, isProTier } = useSession();
  const navigate = useNavigate();
  const [upgradeDowngradePlan, { isLoading }] =
    useUpdateDowngradeSubscriptionMutation();

  const handleConfirmAndPayClick = async () => {
    try {
      if (!!selectedPlan) {
        await upgradeDowngradePlan({
          user_id: appUser.user_id,
          product_key: selectedPlan.metadata?.stripe?.lookup_key || '',
        }).unwrap();
        navigate(AppRoutes.HOME);
        toast.success(
          isProTier
            ? 'Upgrade to Ultra successful'
            : 'Account downgrade started',
        );
      }
    } catch (e) {
      toast.error(
        isProTier ? 'Upgrade to Ultra failed' : 'Account downgrade failed',
      );
      log.error();
    }
  };

  return (
    <button
      type="button"
      className={styles.confirmAndPayButton}
      onClick={handleConfirmAndPayClick}
      disabled={isLoading}
    >
      Confirm & pay
    </button>
  );
};
