import { useEffect, useState } from 'react';
import {
  useBreakpoint,
  useSession,
  useSubscriptionInfo,
  useVisible,
} from 'src/hooks';
import {
  PAYPAL_PAYMENT_IN_PROGRESS,
  PAYPAL_PAYMENT_IN_PROGRESS_MESSAGE,
  REDIRECT_TO_STRIPE_TIMESTAMP,
  SVG_SIZE_L,
  SVG_SIZE_M,
  SVG_SIZE_S,
} from 'src/constants';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppRoutes,
  GTMEvent,
  PaymentPlanInterval,
  PlanSectionType,
  SubscriptionPlan,
} from 'src/types';
import styles from './PaymentBetaPage.module.scss';
import { ArrowLeft, ArrowSquareOut, Info } from '@phosphor-icons/react';
import { PlanSection } from './components/PlanSection';
import { PeriodSwitch } from 'src/components/PeriodSwitch';
import { LEARN_MORE_PRICES_LINK } from 'src/constants/externalLinks';
import { CheckoutSection } from 'src/pages/PaymentBetaPage/components/CheckoutSection';
import { NinjaAgentsOutlinedIcon } from 'src/images/icons/ninjaAgentsOutlined';
import { useGetSubscriptionPlansQuery } from 'src/store/services';
import { LoadingOverlay } from 'src/pages/PaymentBetaPage/components/LoadingOverlay';
import { sendGTMEvent } from 'src/utils';
import cn from 'classnames';
import { UpgradeDowngradeSection } from './components/UpgradeDowngradeSection';
import { getWithExpiry } from 'src/utils/localStorage';
import { toast } from 'react-toastify';

export const PaymentBetaPage = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<PaymentPlanInterval>(
    PaymentPlanInterval.MONTHLY,
  );

  const [selectedPlan, setSelectedPlan] = useState<
    SubscriptionPlan | undefined
  >();
  const [selectedPlanSection, setSelectedPlanSection] = useState<
    PlanSectionType | undefined
  >();

  const {
    isOpenTier,
    appUser,
    isEnterpriseTier,
    isProTier,
    isUltraTier,
    isSubscribedTier,
  } = useSession();

  const { subscriptionInfo } = useSubscriptionInfo();

  const { isMobile } = useBreakpoint();

  const PlanSections: [PlanSectionType, PlanSectionType, PlanSectionType] = [
    'Basic',
    'Pro',
    'Ultra',
  ];

  const PlanSectionsUpgradeDowngrade: [PlanSectionType, PlanSectionType] = [
    'Pro',
    'Ultra',
  ];

  const {
    isVisible: isEmbeddedCheckoutVisible,
    handleVisibilitySet: onOpenEmbeddedCheckoutVisible,
    handleVisibilityRemove: onCloseEmbeddedCheckoutVisible,
  } = useVisible();

  const { data: plans, isLoading: isPlansLoading } =
    useGetSubscriptionPlansQuery({ user_id: appUser.user_id });

  const navigate = useNavigate();

  const isShowUpgrade = isProTier;
  const isShowDowngrade = isUltraTier;

  const handleOpenCheckout = async (
    type: PlanSectionType,
    period: PaymentPlanInterval,
  ) => {
    if (type !== 'Basic') {
      if (period === PaymentPlanInterval.MONTHLY) {
        sendGTMEvent(
          type === 'Pro'
            ? GTMEvent.GET_PRO_MONTHLY_CLICK
            : GTMEvent.GET_ULTRA_MONTHLY_CLICK,
        );
      } else {
        sendGTMEvent(
          type === 'Pro'
            ? GTMEvent.GET_PRO_ANNUAL_CLICK
            : GTMEvent.GET_ULTRA_ANNUAL_CLICK,
        );
      }
    }
    setSelectedPlanSection(type);
    const plan = plans?.find(
      (plan) => plan.period === period && plan.plan_tier === type.toLowerCase(),
    );
    if (plan) {
      setSelectedPlan(plan);
      onOpenEmbeddedCheckoutVisible();
    }
  };

  const handleCloseCheckout = () => {
    onCloseEmbeddedCheckoutVisible();
    setSelectedPlan(undefined);
    setSelectedPlanSection(undefined);
  };

  useEffect(() => {
    if (appUser.user_id && isOpenTier) {
      localStorage.setItem(
        REDIRECT_TO_STRIPE_TIMESTAMP,
        dayjs().valueOf().toString(),
      );
      navigate(AppRoutes.LOGIN);
    } else if (
      (appUser.user_id && isEnterpriseTier) ||
      (!!subscriptionInfo && subscriptionInfo?.has_scheduled_downgrade)
    ) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenTier, appUser.user_id, isEnterpriseTier, subscriptionInfo]);

  useEffect(() => {
    if (isSubscribedTier && !!subscriptionInfo?.interval) {
      setSelectedPeriod(subscriptionInfo?.interval);
    }
  }, [isSubscribedTier, subscriptionInfo]);

  const handleRemoveFlagFromLS = () => {
    if (!!localStorage.getItem(REDIRECT_TO_STRIPE_TIMESTAMP)) {
      localStorage.removeItem(REDIRECT_TO_STRIPE_TIMESTAMP);
    }
  };

  const handleBackButtonClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_BACK_BUTTON_CLICK);
    handleRemoveFlagFromLS();
  };

  const handleCompareAllPlanFeaturesClick = () => {
    sendGTMEvent(GTMEvent.PAYMENT_COMPARE_PLAN_FEATURES_CLICK);
  };

  const handlePeriodToggle = (period: PaymentPlanInterval) => {
    setSelectedPeriod(period);
    const plan = plans?.find(
      (plan) =>
        plan.period === period &&
        plan.plan_tier === selectedPlanSection?.toLowerCase(),
    );
    if (plan) {
      setSelectedPlan(plan);
      onOpenEmbeddedCheckoutVisible();
    }
  };

  useEffect(() => {
    const paypalPaymentInProgress =
      getWithExpiry(PAYPAL_PAYMENT_IN_PROGRESS) === 'true';

    if (paypalPaymentInProgress) {
      toast.info(
        `${PAYPAL_PAYMENT_IN_PROGRESS_MESSAGE} Please wait until it's completed.`,
      );
      navigate(AppRoutes.HOME);
    }
  }, [navigate]);

  // TODO: stan - temporary until we have upgrade for Paypal ready
  useEffect(() => {
    if (isSubscribedTier && subscriptionInfo?.source === 'paypal') {
      navigate(AppRoutes.HOME);
    }
  }, [navigate, isSubscribedTier, subscriptionInfo?.source]);

  const footerComponent = (
    <div className={styles.footer}>
      <div className={styles.hints}>
        <div className={styles.hintRow}>
          <span className={styles.asteriskSign}>*</span>
          <span>
            Access to external models may be restricted if usage reaches
            excessive levels
          </span>
        </div>
        <div className={styles.hintRow}>
          <NinjaAgentsOutlinedIcon width={SVG_SIZE_M} height={SVG_SIZE_M} />
          <span>
            Ninja LLM: Fine-tuned and powered by Ninja’s infrastructure
          </span>
        </div>
        <div className={styles.hintRow}>
          <Info size={SVG_SIZE_S} />
          <span>Cancel any time</span>
        </div>
      </div>
      <a
        href={LEARN_MORE_PRICES_LINK}
        target="_blank"
        rel="noreferrer"
        className={styles.comparePlanLink}
        onClick={handleCompareAllPlanFeaturesClick}
      >
        <ArrowSquareOut size={SVG_SIZE_M} />
        <span>Compare all plan feature</span>
      </a>
    </div>
  );

  return (
    <div className={styles.root}>
      {isPlansLoading && <LoadingOverlay label={'Loading plans...'} />}

      <div className={styles.centerPaymentPart}>
        <div className={styles.header}>
          <Link
            to={AppRoutes.HOME}
            className={styles.goBackLink}
            onClick={handleBackButtonClick}
          >
            <ArrowLeft size={SVG_SIZE_L} />
          </Link>
          <h3 className={styles.title}>Select your plan</h3>
        </div>

        {!isShowUpgrade && !isShowDowngrade && (
          <div className={styles.periodSwitchWrapper}>
            <PeriodSwitch
              selectedPeriod={selectedPeriod}
              onChangePeriod={handlePeriodToggle}
            />
          </div>
        )}
        <div
          className={cn(styles.planSectionWrapper, {
            [styles.withoutPeriodSelect]: isShowDowngrade || isShowUpgrade,
          })}
        >
          <div className={styles.planWrapper}>
            {(isShowUpgrade || isShowDowngrade
              ? PlanSectionsUpgradeDowngrade
              : PlanSections
            ).map((item) => (
              <PlanSection
                key={item}
                type={item}
                onOpenCheckout={handleOpenCheckout}
                period={selectedPeriod}
                selected={selectedPlanSection}
                plans={plans}
              />
            ))}
            {isMobile && footerComponent}
          </div>
          {!isMobile && footerComponent}
        </div>
      </div>

      {isShowUpgrade || isShowDowngrade ? (
        <UpgradeDowngradeSection
          isOpen={isEmbeddedCheckoutVisible}
          onClose={handleCloseCheckout}
          selectedPlan={selectedPlan}
        />
      ) : (
        <CheckoutSection
          isOpen={isEmbeddedCheckoutVisible}
          onClose={handleCloseCheckout}
          selectedPlan={selectedPlan}
        />
      )}
    </div>
  );
};
