import { useState } from 'react';
import { useVisible, useSession, useLogout } from 'src/hooks';
import { Button } from 'src/components/Button';
import { DeleteAccountModal } from 'src/pages/ManageTasksChatPage/components/AccessAndDataSettings/components/DeleteAccountModal';
import { Modal } from 'src/components/Modal';
import log from 'src/utils/logger';
import { UserCircleMinus } from '@phosphor-icons/react';
import styles from 'src/pages/ManageAccountPage/components/General/General.module.scss';
import { useDeleteUserAccountMutation } from 'src/store/services/usersApi';
import { toast } from 'react-toastify';

const DELETED_TEXT = 'Account succesfully deleted';
const ERROR_DELETING_TEXT = 'Error deleting account';

export const DeleteAccount = () => {
  const [isDeleting, setIsDeleting] = useState(false);

  const { isVisible, handleVisibilitySet, handleVisibilityRemove } =
    useVisible();

  const { appUser } = useSession();
  const { logout } = useLogout();
  const [deleteUserAccount] = useDeleteUserAccountMutation();

  const handleDeleteAccount = async () => {
    try {
      log.debug('delete account ' + appUser.user_id);
      setIsDeleting(true);
      try {
        await deleteUserAccount(appUser.user_id).unwrap();
        log.debug('account deleted');
        setIsDeleting(false);
        logout();
        toast(DELETED_TEXT);
      } catch (delete_error) {
        log.error(delete_error);
        setIsDeleting(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        toast(ERROR_DELETING_TEXT + ': ' + (delete_error as any).data.detail);
      }
    } catch (error: unknown) {
      log.error(error);
      setIsDeleting(false); // Ensure to reset even if there's an error
    }
  };

  const handleOnClose = () => {
    setIsDeleting(false);
  };

  return (
    <div className={styles.dataControlContentRed}>
      <p className={styles.dataControlSubtitleRed}>Delete account</p>

      <Button
        onClick={handleVisibilitySet}
        className="nj-settings-tab-content--delete-account-button"
      >
        <UserCircleMinus size={24} />
      </Button>

      <Modal
        open={isDeleting}
        container={document.body}
        classNames={{
          root: 'nj-modal--root',
          overlay: 'nj-modal--overlay',
          modal: 'nj-modal--delete-account',
        }}
        showCloseIcon={false}
        center
        closeOnOverlayClick={false}
        onClose={handleOnClose}
      >
        <div className="nj-delete--executing">
          <span className="nj-delete--progress-icon" />
          <span className="nj-delete--executing-title">Deleting account</span>
        </div>
      </Modal>

      <DeleteAccountModal
        open={isVisible}
        title="Delete account"
        submitTitle="Confirm deletion"
        onSubmit={handleDeleteAccount}
        onClose={handleVisibilityRemove}
      >
        <ul>
          <li>
            Deleting your account is permanent, this action cannot be undone.
          </li>
          <li>
            Your data will be deleted within 60 days, except we may retain a
            limited set of data for longer where required or permitted by law.
          </li>
          <li>Any active subscriptions will be cancelled.</li>
        </ul>
      </DeleteAccountModal>
    </div>
  );
};
